import React, { memo, RefObject, useCallback, useContext } from 'react';
import { ResponsiveUnit } from 'src/components/common/ResponsiveUnit';
import styled, { css } from 'styled-components';
import { CleanButton } from 'src/components/common/Button';
import { dirRTL, fromTheme } from 'src/styles/theme';
import { LayoutRegisterContext } from 'src/providers/LayoutRegisterProvider';
import { transparentize } from 'polished';
import { media } from 'src/styles/designs';
import { Events } from 'src/graphql/schema.graphql';
import { useFlattenEvent } from 'src/modules/events/hooks/useFlattenEvent';
import { FormatDate } from 'src/components/common/FormatDate';
import { useTimeState } from 'src/hooks/useTimeState';
import { useTranslate } from 'react-t7e';

const StickyContainer = styled.div`
  position: sticky;
  top: var(--header-fixed-height);
  height: calc(100vh - var(--header-fixed-height));
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 50px 0 calc(50px + var(--header-fixed-height));

  ${media.w.lessThan.desktop} {
    margin-left: calc(-1 * var(--constraint-container-left-margin) + 6px);
  }
`;

const Container = styled.nav`
  position: relative;
  width: 110px;
  overflow: hidden;

  ${media.w.greaterThanOrEqual.desktop} {
    width: 200px;
  }
`;

const Line = styled.div`
  position: absolute;
  top: 0;
  left: 4rpx;
  bottom: 0;
  width: 1rpx;
  opacity: 0.4;
  background: linear-gradient(
    to bottom,
    ${fromTheme((a) => transparentize(1, a.text.palette.primary))},
    ${fromTheme((a) => a.text.palette.primary)} 15%,
    ${fromTheme((a) => a.text.palette.primary)} 85%,
    ${fromTheme((a) => transparentize(1, a.text.palette.primary))} 100%
  );

  ${media.w.greaterThanOrEqual.desktop} {
    left: 5rpx;
  }

  ${dirRTL} {
    right: 4rpx;
    left: auto;
    ${media.w.greaterThanOrEqual.desktop} {
      right: 5rpx;
      left: auto;
    }
  }
`;

const Date = styled.span`
  position: relative;
  grid-area: date;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Dot = styled.span`
  position: absolute;
  left: -20px;
  top: 50%;
  ${dirRTL} {
    right: -20px;
    left: auto;
  }

  transform: translateY(-50%) scale(0.6);

  width: 9rpx;
  height: 9rpx;
  background: ${fromTheme((a) => a.text.palette.primary)};
  border-radius: 50%;
  transition: all 300ms ${fromTheme((a) => a.animation.easing.decelerated)};

  ${media.w.greaterThanOrEqual.desktop} {
    width: 11rpx;
    height: 11rpx;
  }
`;

const Name = styled.span`
  grid-area: name;
  font-size: 80%;
  transition: all 300ms ${fromTheme((a) => a.animation.easing.decelerated)};
`;

const Form = styled.span<{ form?: string }>`
  padding: 2px 4px;
  text-transform: uppercase;
  line-height: 1;
  font-size: 60%;

  ${({ form }) => {
    switch (form) {
      case 'hybrid':
        return css`
          color: #ffffff;
          background-color: #4f4f4f;
        `;
      case 'offline':
        return css`
          color: #4f4f4f;
          background-color: #f2f2f2;
        `;
      default:
        return css`
          color: #ffffff;
          background-color: #cd0338;
        `;
    }
  }};
`;

const Item = styled(CleanButton)<{ $active?: boolean }>`
  padding-left: 20px;
  font-weight: 300;
  font-size: 10px;
  line-height: 1.25;
  display: grid;
  letter-spacing: 0.64rpx;
  text-transform: uppercase;
  margin: 10px 0;
  transform-origin: left center;
  transition: all 300ms ${fromTheme((a) => a.animation.easing.decelerated)};
  grid-template-areas: 'date date' 'name name';
  grid-template-columns: auto auto;
  grid-column-gap: 6px;
  grid-row-gap: 4px;
  text-align: left;
  ${dirRTL} {
    padding-left: 0;
    padding-right: 20px;
  }

  &:not(:last-child):not(.EventPresentationTimelineItem-active) {
    margin-bottom: 16px;
  }

  &.EventPresentationTimelineItem-active {
    font-weight: bold;
    font-size: 14px;

    & ${Dot} {
      transform: translateY(-50%) scale(1);
    }

    & > ${Name} {
      opacity: 0;
      transform: translateX(100px);
    }
  }

  &.EventPresentationTimelineItem-active + & {
    font-size: 13px;
    letter-spacing: 0.06em;
  }

  ${media.w.greaterThanOrEqual.desktop} {
    font-size: 11px;

    &.EventPresentationTimelineItem-active {
      font-size: 20px;
    }

    &.EventPresentationTimelineItem-active + & {
      font-size: 16px;
      letter-spacing: 0.06em;
    }
  }
`;

const TimelineItem = memo<{
  event: Events;
  active?: boolean;
  px: number;
  sectionsRef: RefObject<Record<string, HTMLDivElement | null>>;
}>(({ event, active, px, sectionsRef }) => {
  const layoutRegisterContext = useContext(LayoutRegisterContext);

  const onClick = useCallback(() => {
    const section = sectionsRef.current?.[event.id];
    if (section != null) {
      window.scrollTo({
        top:
          section.getBoundingClientRect().top +
          window.scrollY -
          (layoutRegisterContext?.ui.height?.header.fixed ?? 0) * px,
        behavior: 'smooth',
      });
    }
  }, [px, layoutRegisterContext?.ui.height?.header.fixed]);

  const flatEvent = useFlattenEvent(event);

  const { isNow } = useTimeState(event);

  const { _ } = useTranslate();

  return (
    <Item
      key={event.id}
      onClick={onClick}
      className={active ? 'EventPresentationTimelineItem-active' : ''}
    >
      <Date>
        <Dot />
        {isNow ? (
          <strong>{_('now')}</strong>
        ) : (
          <FormatDate format="dd.LL">{flatEvent.startDate}</FormatDate>
        )}
        <Form form={flatEvent.form}>{flatEvent.form}</Form>
      </Date>
      <Name>
        {flatEvent.type?.name ? (
          <>
            <strong>{flatEvent.type.name}</strong>
            <br />
            {flatEvent.name}
          </>
        ) : (
          flatEvent.name
        )}
      </Name>
    </Item>
  );
});

export interface EventPresentationTimelineProps {
  activeId?: string;
  events: Events[];
  sectionsRef: RefObject<Record<string, HTMLDivElement | null>>;
}

export const EventPresentationTimeline = memo<EventPresentationTimelineProps>(
  ({ activeId, events, sectionsRef }) => (
    <ResponsiveUnit>
      {(px) => (
        <StickyContainer>
          <Container>
            <Line />
            {events.map((a) => (
              <TimelineItem
                key={a.id}
                event={a}
                px={px}
                active={a.id === activeId}
                sectionsRef={sectionsRef}
              />
            ))}
          </Container>
        </StickyContainer>
      )}
    </ResponsiveUnit>
  ),
);
