import React from 'react';
import { EventsPresentationStaticSection } from 'src/modules/events/components/EventsPresentationGallery/EventsPresentationStaticSection';
import { EventsPresentationGallery } from 'src/modules/events/components/EventsPresentationGallery';
import { useUpcomingEventsListQuery } from 'src/modules/events/hooks/useUpcomingEventsListQuery';
import { EventsGalleryTemplate } from 'src/modules/events/templates/EventsGalleryTemplate';

export const UpcomingEventsTemplate = () => {
  const { data: events } = useUpcomingEventsListQuery();

  return (
    <>
      <EventsGalleryTemplate type="upcoming" hasResults={!!events?.length}>
        {events && events.length > 0 && (
          <EventsPresentationGallery events={events} />
        )}
      </EventsGalleryTemplate>

      <EventsPresentationStaticSection />
    </>
  );
};
