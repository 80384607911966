import React, { forwardRef, memo } from 'react';
import styled from 'styled-components';
import { ConstraintContainer } from 'src/components/common/ConstraintContainer';
import Image from 'next/image';
import { useInViewEffect } from 'react-hook-inview';
import { EventPresentationContent } from 'src/modules/events/components/EventsPresentationGallery/EventPresentationContent';
import useMergedRef from '@react-hook/merged-ref';
import { motion } from 'framer-motion';
import { media } from 'src/styles/designs';
import { Events } from 'src/graphql/schema.graphql';
import { DirectusImage } from 'src/components/common/DirectusImage';
import { dirRTL } from 'src/styles/theme';

export interface EventPresentationProps {
  event: Events;
  isFirst?: boolean;
  onIntersection: (id: string, intersectionRatio: number) => void;
}

const Section = styled(motion.li)<{ $background?: string }>`
  min-height: 600px;
  max-height: 900px;
  height: calc(100vh - var(--header-fixed-height) + 1px);

  background: ${({ theme, $background }) =>
    $background ?? theme.palette.background};

  &:first-of-type {
    height: calc(100vh - var(--header-static-height) + 1px);
  }
`;

const Container = styled(ConstraintContainer)`
  position: relative;
  height: 100%;
`;

const BackgroundImage = styled(Image)`
  object-fit: cover;
  object-position: 20% top;

  ${media.w.greaterThanOrEqual.desktop} {
    object-fit: contain;
    object-position: 70% center;
    ${dirRTL} {
      object-position: 0% center;
    }
  }
`;

export const EventPresentation = memo(
  forwardRef<HTMLDivElement, EventPresentationProps>(
    ({ event, onIntersection }, ref) => {
      const observerRef = useInViewEffect(
        ([entry]) => {
          onIntersection(event.id, entry.intersectionRatio);
        },
        { threshold: [0.25, 0.5, 0.75, 1] },
        [onIntersection],
      );

      const mergedRef = useMergedRef(observerRef, ref);

      const backgroundImage = event.image ?? event.type?.image;

      return (
        <Section
          ref={mergedRef}
          $background={event.background ?? event.type?.background}
        >
          <Container>
            {backgroundImage && (
              <BackgroundImage
                as={DirectusImage}
                src={backgroundImage.id}
                layout="fill"
              />
            )}
            <EventPresentationContent event={event} />
          </Container>
        </Section>
      );
    },
  ),
);

EventPresentation.displayName = 'EventPresentation';
