import React from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-t7e';
import { Events } from 'src/graphql/schema.graphql';
import { route } from 'src/services/route';
import Calendar from 'src/components/icons/Calendar';
import { Button, FullWidthButton } from 'src/components/common/Button';
import { TrackedLink } from 'src/components/TrackedLink';
import { ChevronRight } from 'src/components/icons/ChevronRight';
import { UserEventsEventButton } from 'src/modules/events/components/UserEvents/UserEventsEventButton';

export interface EventPresentationCTAsProps {
  event: Events;
  isUpcoming: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  gap: 8px;

  margin-top: 24px;

  ${FullWidthButton} {
    min-width: 100%;
  }
`;

const CalendarButton = styled(Button)`
  margin-top: 12px;
  max-width: max-content !important;
`;

export const EventPresentationCTAs = ({
  event,
  isUpcoming,
}: EventPresentationCTAsProps) => {
  const { _ } = useTranslate();

  return (
    <Container>
      <TrackedLink href={route.event(event).page()} passHref>
        <FullWidthButton
          icon={ChevronRight}
          iconProps={{ position: 'right', size: 12 }}
          variant="secondary"
        >
          {_('SEE MORE')}
        </FullWidthButton>
      </TrackedLink>

      <UserEventsEventButton event={event} />

      {isUpcoming && (
        <CalendarButton
          kind="text"
          forwardedAs="a"
          href={route.event(event).ics()}
          icon={Calendar}
        >
          {_('Add to calendar')}
        </CalendarButton>
      )}
    </Container>
  );
};
