import { APIs } from 'src/models/apis';
import { QueryKey } from 'src/models/query';
import { useQuery, UseQueryResult } from 'react-query';
import { useAPIs } from 'src/hooks/useAPIs';
import { eventsPageGqlQuery } from 'src/modules/events/graphql/events-page.query';
import { EventsPage } from 'src/graphql/schema.graphql';

export const fetchUpcomingEventsPage = async (apis: APIs) => {
  const directus = await apis.directus();

  return directus.query(eventsPageGqlQuery).then((d) => d.eventsPage);
};

export const useUpcomingEventsPageQuery = (): UseQueryResult<EventsPage> => {
  const apis = useAPIs();

  return useQuery(QueryKey.UpcomingEventsPage, () =>
    fetchUpcomingEventsPage(apis),
  );
};
