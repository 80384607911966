import { APIs } from 'src/models/apis';
import { QueryKey } from 'src/models/query';
import { parseISO, startOfDay } from 'date-fns';
import { useQuery } from 'react-query';
import { useAPIs } from 'src/hooks/useAPIs';
import { fetchEventsList } from 'src/modules/events/hooks/useEventsListQuery';

export const fetchUpcomingEventsList = async (apis: APIs) => {
  const events = await fetchEventsList(apis, {
    filters: {
      endDate: { _gte: startOfDay(new Date()).toISOString() },
    },
  });

  return events
    .filter((event) => parseISO(event.endDate).getTime() > new Date().getTime())
    .sort(
      (a, b) =>
        parseISO(a.startDate).getTime() - parseISO(b.startDate).getTime(),
    );
};

export const useUpcomingEventsListQuery = () => {
  const apis = useAPIs();

  return useQuery(QueryKey.UpcomingEventsList, () =>
    fetchUpcomingEventsList(apis),
  );
};
