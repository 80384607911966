import { VendorGetServerSideProps } from 'vendor/utils/VendorGetServerSideProps';
import { QueryKey } from 'src/models/query';
import { configureSSR } from 'src/utils/configureSSR';
import { fetchUpcomingEventsList } from 'src/modules/events/hooks/useUpcomingEventsListQuery';
import { UpcomingEventsTemplate } from 'src/modules/events/templates/UpcomingEventsTemplate';

export const getServerSideProps = VendorGetServerSideProps((ctx) =>
  configureSSR(ctx, {
    layout: {
      variant: 'default',
      tvbar: true,
    },
    cacheControl: {
      maxAge: 600,
      sMaxAge: 1200,
    },
    actions: {
      common: () => [
        ctx.queryClient.prefetchQuery(QueryKey.UpcomingEventsList, () =>
          fetchUpcomingEventsList(ctx.apis),
        ),
      ],
    },
  }),
);

export default UpcomingEventsTemplate;
