import React, { memo, useCallback, useRef, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ConstraintContainer } from 'src/components/common/ConstraintContainer';
import { redTheme } from 'src/styles/theme';
import { EventPresentation } from 'src/modules/events/components/EventsPresentationGallery/EventPresentation';
import { EventPresentationTimeline } from 'src/modules/events/components/EventsPresentationGallery/EventPresentationTimeline';
import { motion } from 'framer-motion';
import { Events } from 'src/graphql/schema.graphql';

export interface EventsPresentationGalleryProps {
  events: Events[];
}

const StickyUIContainer = styled.div`
  color: ${({ theme }) => theme.text.palette.primary};
  position: relative;
`;

const StickyUISpace = styled(ConstraintContainer)`
  height: 0;
`;

const TimelineContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 50;
  width: 0;
`;

const Content = styled(motion.ol)`
  margin: 0;
  padding: 0;
`;

const animationConfig = {
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

export const EventsPresentationGallery = memo<EventsPresentationGalleryProps>(
  function EventsPresentationGallery({ events }) {
    const [activeNavigationItem, setActiveNavigationItem] = useState<string>(
      events[0].id,
    );

    const intersectionValues = useRef<Record<string, number>>({});

    const onIntersection = useCallback(
      (id: string, intersectionRatio: number) => {
        intersectionValues.current[id] = intersectionRatio;

        const current = Object.entries(intersectionValues.current).find(
          ([, value]) => value >= 0.5,
        )?.[0];

        if (current != null) {
          setActiveNavigationItem(current);
        }
      },
      [],
    );

    const sectionsRef = useRef<Record<string, HTMLDivElement | null>>({});

    return (
      <ThemeProvider theme={redTheme}>
        <StickyUIContainer>
          <StickyUISpace>
            <TimelineContainer>
              <EventPresentationTimeline
                events={events}
                sectionsRef={sectionsRef}
                activeId={activeNavigationItem}
              />
            </TimelineContainer>
          </StickyUISpace>

          <Content
            variants={animationConfig}
            initial="hide"
            animate="show"
            key="EventsPresentationGalleryAnimation"
          >
            {events.map((a, i) => (
              <EventPresentation
                isFirst={i === 0}
                key={a.id}
                event={a}
                ref={(b) => (sectionsRef.current[a.id] = b)}
                onIntersection={onIntersection}
              />
            ))}
          </Content>
        </StickyUIContainer>
      </ThemeProvider>
    );
  },
);
