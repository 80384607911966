import { EventsPage } from 'src/graphql/schema.graphql';
import { gql, GqlFragment } from 'src/utils/gql/query';

export const eventsPageGqlQuery: GqlFragment<{ eventsPage: EventsPage }> = gql`
  query EventsPageList {
    eventsPage {
      neauviaExpertsCertificationLink
    }
  }
`;
