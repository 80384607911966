import React from 'react';
import styled from 'styled-components';
import { dirRTL, fromTheme } from 'src/styles/theme';
import { ConstraintContainer } from 'src/components/common/ConstraintContainer';
import { H1 } from 'src/components/common/typography/Header';
import Image from 'next/image';
import lolEventSectionImage from 'src/modules/events/assets/lol-event-section.jpg';
import { media } from 'src/styles/designs';
import { useTranslate } from 'react-t7e';
import { useUpcomingEventsPageQuery } from 'src/modules/events/hooks/useUpcomingEventsPageQuery';
import { TrackedLink } from 'src/components/TrackedLink';
import { Button } from 'src/components/common/Button';

const LeftImageSection = styled(ConstraintContainer)`
  display: grid;
  grid-template-areas: 'image subtitle' 'image title' 'image description' 'image cta' 'image .';
  grid-template-columns: auto 1fr;
  grid-template-rows: 3fr auto auto auto 2fr;
  justify-content: center;
  grid-row-gap: 15px;
  grid-column-gap: 10px;

  ${media.w.lessThan.desktop} {
    margin-top: 40px;
    padding-left: 0;
    ${dirRTL} {
      padding-left: var(--constraint-container-left-inner-margin);
      padding-right: 0;
    }
  }

  ${media.w.greaterThanOrEqual.desktop} {
    grid-row-gap: 30px;
    grid-column-gap: 40px;
    margin-top: -30px;
    grid-template-columns: auto 440px;

    &:last-of-type {
      margin-bottom: calc(-1 * var(--footer-static-height) * 0.27);
    }
  }
`;

const ImageContainer = styled.div`
  grid-area: image;
  justify-self: center;
  height: 340px;
  width: 130px;
  position: relative;

  ${media.w.greaterThanOrEqual.desktop} {
    width: 386px;
    height: 578px;
  }
`;

const StyledImage = styled(Image)`
  object-fit: cover;
  object-position: center center;
`;

const Subtitle = styled.h4`
  grid-area: subtitle;
  padding-left: 30px;
  align-self: end;

  color: ${fromTheme((a) => a.text.palette.secondary)};
  font-weight: 300;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.64rpx;

  ${media.w.greaterThanOrEqual.desktop} {
    margin-bottom: 20px;
    padding-left: 40px;
  }
  ${dirRTL} {
    padding-left: 0;
    padding-right: 30px;

    ${media.w.greaterThanOrEqual.desktop} {
      padding-left: 0;
      padding-right: 40px;
    }
  }
`;

const Title = styled(H1).attrs({ as: 'h2' })`
  grid-area: title;
  margin-bottom: 10px;
`;

const Description = styled.p`
  grid-area: description;
  padding-left: 30px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;

  ${media.w.greaterThanOrEqual.desktop} {
    width: 250px;
    padding-left: 40px;
  }

  ${dirRTL} {
    padding-left: 0;
    padding-right: 30px;

    ${media.w.greaterThanOrEqual.desktop} {
      padding-left: 0;
      padding-right: 40px;
    }
  }
`;

const CTA = styled.div`
  grid-area: cta;
  padding-left: 30px;

  color: ${fromTheme((a) => a.text.font.secondary)};
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.64rpx;
  text-transform: uppercase;

  ${media.w.greaterThanOrEqual.desktop} {
    padding-left: 40px;
  }
  ${dirRTL} {
    padding-left: 0;
    padding-right: 30px;

    ${media.w.greaterThanOrEqual.desktop} {
      padding-left: 0;
      padding-right: 40px;
    }
  }
`;

export const EventsPresentationStaticSection = () => {
  const { _ } = useTranslate();
  const { data } = useUpcomingEventsPageQuery();

  return (
    <>
      <LeftImageSection>
        <ImageContainer>
          <StyledImage src={lolEventSectionImage} layout="fill" />
        </ImageContainer>
        <Subtitle>{_('EXCLUSIVE EVENTS')}</Subtitle>
        <Title>{_('NEAUVIA EXPERTS CERTIFICATION')}</Title>
        <Description>
          {_('NEAUVIA EXPERTS CERTIFICATION description')}
        </Description>
        <CTA>
          <TrackedLink
            href={data?.neauviaExpertsCertificationLink ?? ''}
            passHref
          >
            <Button kind="text" as="a">
              {_('Discover more')}
            </Button>
          </TrackedLink>
        </CTA>
      </LeftImageSection>
    </>
  );
};
