import styled, { css } from 'styled-components';
import { dirRTL, fromTheme } from 'src/styles/theme';
import { media } from 'src/styles/designs';

export const H1 = styled.h1<{ $nail?: boolean | 'outside' }>`
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.64rpx;
  text-transform: uppercase;

  font-size: 24px;
  line-height: 120%;

  color: ${fromTheme((theme) => theme.text.palette.accent)};
  margin-bottom: 20px;

  ${media.w.greaterThanOrEqual.desktop} {
    font-size: 32px;
    margin-bottom: 30px;
  }

  ${({ $nail = true }) =>
    $nail &&
    css`
      position: relative;
      padding-left: 30px;

      ${media.w.greaterThanOrEqual.desktop} {
        padding-left: 40px;
      }

      &::before {
        content: '';
        background-color: currentColor;
        position: absolute;
        top: -5px;
        bottom: 0;
        height: 70px;
        left: 5px;
        width: 17px;
        clip-path: polygon(0 0, 40% 0, 100% 100%, 90% 100%);
      }

      ${dirRTL} {
        padding-left: 0;
        padding-right: 30px;

        ${media.w.greaterThanOrEqual.desktop} {
          padding-left: 0;
          padding-right: 40px;
        }

        &::before {
          left: auto;
          right: 5px;
          clip-path: polygon(60% 0, 100% 0, 10% 100%, 0 100%);

          ${media.w.greaterThanOrEqual.desktop} {
            right: 0;
          }
        }
      }

      ${$nail === 'outside' &&
      css`
        padding: 0 26px;
        &:before {
          width: 10px;
          height: 41px;
        }

        ${media.w.greaterThanOrEqual.desktop} {
          margin-left: -35px;
          margin-bottom: 30px;
        }
      `}
    `}
`;

export const H1Smaller = styled.h1<{ $nail?: boolean }>`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.64rpx;
  text-transform: uppercase;

  color: ${fromTheme((theme) => theme.text.palette.accent)};
  margin-bottom: 20px;

  ${media.w.greaterThanOrEqual.desktop} {
    font-size: 16px;
    margin-bottom: 20px;
  }

  ${({ $nail = true }) =>
    $nail &&
    css`
      position: relative;
      padding-left: 12px;

      &::before {
        content: '';
        background-color: currentColor;
        position: absolute;
        clip-path: polygon(0 0, 40% 0, 100% 100%, 90% 100%);
        top: -2px;
        height: 21px;
        left: 0;
        width: 5px;
      }

      ${dirRTL} {
        padding-left: 0;
        padding-right: 12px;

        &::before {
          left: auto;
          right: 0;
          clip-path: polygon(60% 0, 100% 0, 10% 100%, 0 100%);
        }
      }
    `}
`;

export const H2 = styled.h2`
  font-size: 14px;

  font-weight: 400;
  letter-spacing: 0.04em;
  text-align: left;

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: ${({ theme }) => theme.text.palette.accent};
  margin-bottom: ${({ theme }) => theme.spacing.gap * 0.5}px;

  ${media.w.lessThan.desktop} {
    font-size: 16px;
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.gap * 0.75}px;
  }
`;

export const H3 = styled.h3`
  color: ${fromTheme((theme) => theme.text.palette.primary)};
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  margin-left: -30px;
  margin-bottom: 18px;
  letter-spacing: 2rpx;

  ${media.w.greaterThanOrEqual.desktop} {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.64rpx;
    margin-left: 0;
  }
  ${dirRTL} {
    margin-left: 0;
    margin-right: -30px;
    ${media.w.greaterThanOrEqual.desktop} {
      text-align: right;
      margin-right: 0;
    }
  }
`;
