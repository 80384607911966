/* eslint-disable react/display-name */
import React, { memo, useRef } from 'react';
import styled from 'styled-components';
import { media } from 'src/styles/designs';
import { lineClamp } from 'src/styles/common';
import { useTranslate } from 'react-t7e';
import { Events } from 'src/graphql/schema.graphql';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { useFlattenEvent } from 'src/modules/events/hooks/useFlattenEvent';
import { EventCountdown } from 'src/modules/events/components/EventCountdown';
import { EventPresentationCTAs } from 'src/modules/events/components/EventsPresentationGallery/EventPresentationCTAs';
import { FormatDate, FormatHour } from 'src/components/common/FormatDate';
import { useTimeState } from 'src/hooks/useTimeState';
import { dirRTL } from 'src/styles/theme';
import { useIsMultidayEvent } from 'src/modules/events/hooks/useIsMultidayEvent';
import { DirectusImage } from 'src/components/common/DirectusImage';

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 215px;
  margin-left: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.w.greaterThanOrEqual.desktop} {
    width: 500px;
    margin-left: 250px;
  }

  ${dirRTL} {
    margin-right: 120px;
    margin-left: 0;
    ${media.w.greaterThanOrEqual.desktop} {
      margin-left: 0;
      margin-right: 250px;
    }
  }
`;

const DateContainer = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  white-space: nowrap;
  margin-bottom: 8px;

  ${media.w.greaterThanOrEqual.desktop} {
    margin-bottom: 12px;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 12px;
  overflow-wrap: break-word;

  ${media.w.greaterThanOrEqual.desktop} {
    margin-bottom: 24px;
  }
`;

const Name = styled.h2`
  font-size: 32px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0.64rpx;
  text-transform: uppercase;
`;

const Title = styled.h3`
  max-width: 350px;

  font-size: 32px;
  font-weight: 200;
  line-height: 1;
  letter-spacing: 0.64rpx;
  text-transform: uppercase;
`;

const Description = styled.p`
  max-width: 350px;

  font-weight: 300;
  font-size: 14px;
  line-height: 150%;

  ${media.w.lessThan.desktop} {
    display: none;
  }
`;

const Tags = styled.p`
  max-width: 350px;

  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  ${lineClamp(3)};

  ${media.w.greaterThanOrEqual.desktop} {
    margin-top: 22px;
    ${lineClamp(2)};
  }
`;

const TagsTitle = styled.span`
  text-transform: uppercase;
  letter-spacing: 0.64rpx;
`;

const LogoContainer = styled.div`
  position: relative;
  width: 122px;
  height: 45px;
  margin-top: -48px;
  margin-bottom: 12px;
  ${media.w.greaterThanOrEqual.desktop} {
    width: 162px;
    height: 64px;
  }
`;

export interface EventPresentationContentProps {
  event: Events;
}

export const EventPresentationContent = memo<EventPresentationContentProps>(
  ({ event }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { _ } = useTranslate();

    const flatEvent = useFlattenEvent(event);

    const { isNow, isUpcoming } = useTimeState(flatEvent);
    const { isMultiDayEvent } = useIsMultidayEvent(flatEvent);

    return (
      <>
        <Container ref={ref}>
          {flatEvent.logo && (
            <LogoContainer>
              <DirectusImage
                src={flatEvent.logo.id}
                layout="fill"
                objectFit="contain"
                objectPosition="0 50%"
              />
            </LogoContainer>
          )}

          <DateContainer>
            {isMultiDayEvent ? (
              isNow ? (
                <>
                  {_('Ends on')}{' '}
                  <FormatDate format="dd.LL">{event.endDate}</FormatDate>{' '}
                  <FormatHour>{event.endDate}</FormatHour>
                </>
              ) : (
                <>
                  {_('Starts at')} <FormatHour>{event.startDate}</FormatHour>
                </>
              )
            ) : (
              <>
                <FormatHour>{event.startDate}</FormatHour>&nbsp;-&nbsp;
                <FormatHour>{event.endDate}</FormatHour>
              </>
            )}
          </DateContainer>

          <TitleContainer>
            {flatEvent.type?.name != null && (
              <>
                <Name>{flatEvent.type?.name}</Name>
                {flatEvent.name && <Title>{flatEvent.name}</Title>}
              </>
            )}
            {flatEvent.type?.name == null && <Name>{flatEvent.name}</Name>}
          </TitleContainer>

          <Description>
            {flatEvent.excerpt && <Markdown>{flatEvent.excerpt}</Markdown>}
          </Description>

          {flatEvent.tags.length > 0 && (
            <Tags>
              <TagsTitle>{_('EVENT TOPICS')}: </TagsTitle>
              {flatEvent.tags.map((a) => a.name).join(', ')}
            </Tags>
          )}

          <EventPresentationCTAs event={event} isUpcoming={isUpcoming} />
        </Container>
        <EventCountdown event={event} />
      </>
    );
  },
);
