import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ConstraintContainer } from 'src/components/common/ConstraintContainer';
import { defaultTheme, redTheme } from 'src/styles/theme';
import { media } from 'src/styles/designs';
import { Paragraph } from 'src/components/common/typography/Text';
import { AnimatePresence } from 'framer-motion';
import { useTranslate } from 'react-t7e';
import { casePathEmptyString } from 'src/utils/casePathEmptyString';
import { StickyMenu } from 'src/components/common/StickyMenu';
import { MetaTitle } from 'src/components/seo/MetaTitle';
import { MetaDescription } from 'src/components/seo/MetaDescription';
import { route } from 'src/services/route';

export interface EventsGalleryTemplateProps {
  type: 'upcoming' | 'past-global' | 'past-local';
  children: ReactNode;
  hasResults: boolean;
  staticMenu?: boolean;
}

const StickyUIContainer = styled.div`
  position: relative;
`;

const StickyMenuContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  width: 100%;
  pointer-events: none;
`;

const StyledStickyMenu = styled(StickyMenu)<{ $static?: boolean }>`
  &&& {
    ${casePathEmptyString('$static')(
      css`
        position: static;
      `,
    )};

    pointer-events: all;
    .sticky-menu__navbar {
      background: transparent;
    }
    .sticky-menu__button {
      justify-content: start;
      ${media.w.greaterThanOrEqual.desktop} {
        min-width: 220px;
      }
    }
  }
`;

const NoResultsContainer = styled(ConstraintContainer)<{ $space?: boolean }>`
  text-align: center;
  padding-top: 100px;

  ${casePathEmptyString('$space')(
    css`
      padding-bottom: 30px;

      ${media.w.greaterThanOrEqual.desktop} {
        padding-bottom: 100px;
      }
    `,
  )};
`;

const NoResultsHeading = styled.h3`
  margin-bottom: 16px;
`;

const stickyMenuSections = [
  {
    id: 'upcoming',
    label: 'upcoming events',
    link: route.events().upcoming(),
  },
  {
    id: 'past-global',
    label: 'past global events',
    link: route.events().pastGlobal(),
  },
  {
    id: 'past-local',
    label: 'past local events',
    link: route.events().pastLocal(),
  },
];

export const EventsGalleryTemplate = ({
  type,
  children,
  hasResults,
  staticMenu = false,
}: EventsGalleryTemplateProps) => {
  const { _ } = useTranslate();

  return (
    <>
      <MetaTitle>{_(`seo.events.title`, `events.${type}`)}</MetaTitle>
      <MetaDescription>
        {_(`seo.events.description`, `events.${type}`)}
      </MetaDescription>

      <StickyUIContainer>
        <StickyMenuContainer>
          <StyledStickyMenu
            $static={staticMenu}
            tabs={stickyMenuSections}
            theme={hasResults && !staticMenu ? redTheme : defaultTheme}
            activeTab={type}
            transparentOnTop={!staticMenu}
          />
        </StickyMenuContainer>

        <AnimatePresence exitBeforeEnter initial={false}>
          {children}
        </AnimatePresence>
      </StickyUIContainer>

      {!hasResults && (
        <NoResultsContainer $space={type === 'upcoming'}>
          <NoResultsHeading>
            {_('Currently, there are no events in this category')}
          </NoResultsHeading>
          <Paragraph>
            {_('Please use different one, or come back later!')}
          </Paragraph>
        </NoResultsContainer>
      )}
    </>
  );
};
